<template>
  <div>
    <div class="panel panel-default mb-3">
      <div class="panel-heading">
        <h5 class="car-title">
          #{{ car.id }}
          <strong class="margin-l-5" style="position: absolute;">
            {{ car.title }}
          </strong>
          <span class="pull-right font-small" style="margin-top: 24px;">
            Удалено <strong class="font-medium">{{ car.deleted_date }}</strong>
          </span>
        </h5>
      </div>
      <div class="panel-body">
        <div class="row">
          <div class="col-12 col-lg-5">
            <img v-if="car.main_photo" :src="car.main_photo" class="img-fluid" :alt="car.title"
                 style="min-height: 100px;">
            <img :alt="car.title" class="img-fluid" src="../../../assets/img/no-car-photo-240-140.png" v-else>

            <ul class="list-group list-group-unbordered">
              <li class="list-group-item no-border pt-0 pb-1">
                <strong class="font-scale-1">{{ car.price }} $</strong>
                <small v-if="car.old_price && car.is_top_price"
                       class="font-medium text-red text-muted margin-l-5 font-small">
                  {{ car.old_price }}$
                </small>
              </li>
              <li class="list-group-item no-border medium-line-height text-muted font-small pb-1 pt-0"
                  v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)">
                {{ car.created_date }}
                <small>
                  <br/>
                  Ответственный <strong>{{ car.creator.name }}</strong>
                </small>
              </li>
            </ul>
          </div>
          <div class="col-12 col-lg-7">
            <ul class="list-group list-group-unbordered">
              <li class="list-group-item pb-1">
                <strong>{{ car.branch.title }}</strong>
                <small v-if="car.branch.address">
                  ({{ car.branch.address }})
                </small>
                <small style="letter-spacing: -.035rem;">
                  <strong v-if="car.specification.engine_title" class="text-black font-small">
                    <span class="font-light">/</span> {{ car.specification.engine_title }}
                  </strong>
                  / {{ car.specification.fuel.title }}
                  / {{ car.specification.gearbox.title }}
                  / {{ car.specification.race }} тыс. км.
                  / {{ car.specification.transmission.title }} привод
                  / {{ car.specification.color.title }}
                  / <strong class="text-black font-small">{{ car.year }}</strong> г.
                </small>
              </li>
              <li class="list-group-item" style="border-bottom: 0; padding-bottom: 0; text-align: right;">
                <clipboard-block :data="car.specification.reg_number" custom_class="btn-default"/>
                <span class="label label-primary margin-r-5">
                  <strong style="font-size: 15px; letter-spacing: .03rem;">
                    {{ car.specification.reg_number }}
                  </strong>
                </span>

                <span class="label label-primary margin-l-5">
                  <strong style="font-size: 14px; letter-spacing: .001rem;">
                    {{ car.specification.vin_code }}
                  </strong>
                </span>
                <clipboard-block :data="car.specification.vin_code" custom_class="btn-default"/>

                <span v-if="car.editor" class="text-right font-small-xs">
                  Обновил <strong>{{ car.editor.name }}</strong> {{ car.updated_date }}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-lg-12 pt-2">
            <span v-if="car.sold" class="font-small">
              Продан за <strong>{{ car.sold.sum }}$</strong> (комиссия <strong>{{ car.sold.commission_sum }}$</strong>)
            </span>
            <button v-if="car.is_deleted && ([1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) || car.creator.id === $store.getters['auth/authUser'].id)"
                    class="btn btn-warning pull-left full-height"
                    title="Восстановить" type="button"
                    v-on:click="restoreCar(car.id)">
              <i class="fas fa-trash-restore-alt"></i>
            </button>

            <router-link :to="{ name: 'car.details', params: { carId: car.id }}"
                         class="btn btn-success pull-right margin-l-5"
                         title="Детали"
                         type="button">
              <i class="fa fa-info-circle"></i>
            </router-link>

            <button v-if="[1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
                    class="btn btn-danger pull-right"
                    title="Полное удаление" type="button"
                    v-on:click="destroyCar(car.id)">
              <i class="fas fa-ban"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipboardBlock from './../../../components/blocks/clipboard-block.vue';

export default {
  name: 'car-archive-item',

  components: {
    ClipboardBlock,
  },

  props: {
    car: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      }
    }
  },

  methods: {
    restoreCar(carId) {
      this.$dialog.confirm('Вы уверены что хотите восстановить запись?', this.$dialogOptions).then(() => {
        API.apiClient.put('/cars/restore/' + carId).then(() => {
          this.EventBus.$emit('card-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
    destroyCar(carId) {
      this.$dialog.confirm('Вы уверены что хотите полностью удалить запись?', this.$dialogOptions).then(() => {
        API.apiClient.delete('/cars/' + carId + '/destroy').then(() => {
          this.EventBus.$emit('card-filter-changed');
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },
  }
}
</script>

<style scoped>

</style>
